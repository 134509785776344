import create, { GetState, SetState } from "zustand";
import { persist } from "zustand/middleware";

export type useAuthType = {
  token: string;
  signIn: (newToken: string) => void;
  signOut: () => void;
};

const restoreToken = sessionStorage.getItem("token")
  ? JSON.parse(sessionStorage.getItem("token") as string).state.token
  : "";

export const useAuth = create<useAuthType>(
  persist(
    (set: SetState<useAuthType>, get: GetState<useAuthType>) => ({
      token: restoreToken || "",
      signIn: (newToken: string) => {
        set({ token: newToken });
      },
      signOut: () => {
        set({ token: "" });
      },
    }),
    {
      name: "token",
      getStorage: () => sessionStorage,
    }
  )
);
