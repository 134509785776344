import axios from "axios";

export type Category = {
    pushNotificationCategoryId: number;
    categoryIdentifier: string;
    categoryShort: string;
    categoryDescription: string;
}

export class CategoryService {
    static GetAll(): Promise<Array<Category>> {
        return axios.get(`/backoffice/push-categories`)
            .then(async (res) => {
                return res.data;
            });
    }
}
