import React from "react";
import EventForm from "./EventForm";

function EventCreatePage() {
  return (
    <div>
      <EventForm action={"CREATE"} />
    </div>
  );
}

export default EventCreatePage;
