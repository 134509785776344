import axios from "axios";
import { SignInResponse } from "../models/auth/SignInResponse";
import { SignInRequest } from "../models/auth/SignInRequest";
import { useMutation } from "react-query";
import { useAuth } from "../stores/useAuth";

const signIn = async (signInDto: SignInRequest): Promise<SignInResponse> => {
  const { data } = await axios.post(`/auth/admin`, signInDto);
  return data;
};

export const useSignIn = () => {
  return useMutation(
    async (signInDto: SignInRequest) => {
      return await signIn(signInDto);
    },
    {
      onSuccess: (signInResponse: SignInResponse) => {
        useAuth.getState().signIn(signInResponse.token);
      },
    }
  );
};
