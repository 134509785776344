import React from "react";
import AdminForm from "./AdminForm";

function AdminCreatePage() {
  return (
    <div>
      <AdminForm action={"CREATE"} />
    </div>
  );
}

export default AdminCreatePage;
