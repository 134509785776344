import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useGetEventProposals, useGetEvents } from "../../queries/useEvent";
import EventsTable from "./EventsTable";

const useStyles = makeStyles({});

function EventsPage() {
  const { data: events, isLoading: eventsLoading } = useGetEvents();
  const { data: eventProposals, isLoading: eventProposalsLoading } =
    useGetEventProposals();
  const classes = useStyles();
  const history = useHistory();

  const onCreate = () => {
    history.push("/events/create");
  };

  return (
    <div className={"w-full flex justify-center"}>
      <div className={""}>
        <div className={"flex justify-between mb-4"}>
          <Typography variant={"h5"}>Events</Typography>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onCreate}
          >
            Create
          </Button>
        </div>
        <div className={"flex justify-center"}>
          {eventsLoading && <CircularProgress color="secondary" />}
          {events && <EventsTable events={events} />}
        </div>

        <div className={"flex justify-between mt-8 mb-4"}>
          <Typography variant={"h5"}>To review Events</Typography>
        </div>
        <div className={"flex justify-center"}>
          {eventProposalsLoading && <CircularProgress color="secondary" />}
          {eventProposals && <EventsTable events={eventProposals} />}
        </div>
      </div>
    </div>
  );
}

export default EventsPage;
