import React from "react";
import CouponForm from "./CouponForm";

function CouponCreatePage() {
  return (
    <div>
      <CouponForm action={"CREATE"} />
    </div>
  );
}

export default CouponCreatePage;
