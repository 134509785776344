import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../stores/useAuth";

export const ProtectedRoute = (props: {
  component: React.FC;
  path: string;
  exact?: boolean;
}) => {
  const { token } = useAuth();

  return token ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/auth/signin" />
  );
};

export default ProtectedRoute;
