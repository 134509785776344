import React, { useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import ConfirmDialog from "../../components/ConfirmDialog";
import moment from "moment";
import { EventPrivateDO } from "../../models/event/EventPrivateDO";
import { useDeleteEventById, useUpdateEvent } from "../../queries/useEvent";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { EventAdminRequestDO } from "../../models/event/EventAdminRequestDO";

const useStyles = makeStyles({
  table: {
    minWidth: 950,
  },
});

type EventsTableType = {
  events: EventPrivateDO[];
};

function EventsTable({ events }: EventsTableType) {
  const delMutationResult = useDeleteEventById();
  const updateMutationResult = useUpdateEvent();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const [toDelete, setToDelete] = useState<EventPrivateDO>();
  const [toPublish, setToPublish] = useState<EventPrivateDO>();

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    eventEntity: EventPrivateDO
  ) => {
    history.push(`/events/${eventEntity.id}`);
  };

  const onEdit = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    eventEntity: EventPrivateDO
  ) => {
    event.stopPropagation();
    history.push(`/events/${eventEntity.id}/edit`);
  };

  const onDelete = async () => {
    try {
      await delMutationResult.mutateAsync(toDelete?.id as number);
      await queryClient.invalidateQueries("useGetEvents");
      await queryClient.invalidateQueries("useGetEventProposals");
      enqueueSnackbar(`Delete event ${toDelete?.title} successfully!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(
        `Delete event ${toDelete?.title} with error: ${e.message}`,
        {
          variant: "error",
        }
      );
    }
  };

  const onPublish = async () => {
    try {
      if (toPublish) {
        const eventAdminRequest: EventAdminRequestDO = {
          ...toPublish,
          id: toPublish?.id,
          isPublished: true,
        };
        await updateMutationResult.mutateAsync(eventAdminRequest);
        await queryClient.invalidateQueries("useGetEvents");
        await queryClient.invalidateQueries("useGetEventProposals");
        await queryClient.invalidateQueries(["useGetEventById", toPublish?.id]);
        enqueueSnackbar(
          `Event ${eventAdminRequest.title} published successfully!`,
          {
            variant: "success",
          }
        );
        history.push("/events");
      }
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(`Error: ${e.message}`, {
        variant: "error",
      });
    }
  };

  const openDeleteDialog = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    eventEntity: EventPrivateDO
  ) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setToDelete(eventEntity);
  };

  const openPublishDialog = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    eventEntity: EventPrivateDO
  ) => {
    event.stopPropagation();
    setPublishOpen(true);
    setToPublish(eventEntity);
  };

  return (
    <div className={"w-full flex justify-center"}>
      <div className={"max-w-6xl"}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Title</TableCell>
                <TableCell align="right">Valid from</TableCell>
                <TableCell align="right">Valid until</TableCell>
                <TableCell align="right">Place</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events &&
                events.map((eventEntity: EventPrivateDO) => (
                  <TableRow
                    className={"cursor-pointer"}
                    hover
                    key={eventEntity?.id}
                    onClick={(event) => onRowClick(event, eventEntity)}
                  >
                    <TableCell component="th" scope="row">
                      {eventEntity?.id}
                    </TableCell>
                    <TableCell align="right">{eventEntity?.title}</TableCell>
                    <TableCell align="right">
                      {moment(eventEntity?.startDate).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell align="right">
                      {moment(eventEntity?.endDate).format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell align="right">{eventEntity?.location}</TableCell>
                    <TableCell align="right">
                      {!eventEntity?.isPublished && (
                        <>
                          <IconButton
                            onClick={(event) => onEdit(event, eventEntity)}
                          >
                            <EditOutlinedIcon color={"primary"} />
                          </IconButton>

                          <IconButton
                            onClick={(event) =>
                              openPublishDialog(event, eventEntity)
                            }
                          >
                            <CheckOutlinedIcon color={"action"} />
                          </IconButton>

                          <IconButton
                            onClick={(event) =>
                              openDeleteDialog(event, eventEntity)
                            }
                          >
                            <DeleteIcon color={"error"} />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <ConfirmDialog
        isOpen={deleteOpen}
        title={"Delete Event"}
        content={"Still wanna delete?"}
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />

      <ConfirmDialog
        isOpen={publishOpen}
        title={"Publish Event"}
        content={"Still wanna publish?"}
        onOk={onPublish}
        onCancel={() => setPublishOpen(false)}
      />
    </div>
  );
}

export default EventsTable;
