import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import {HistoricPush, PushesService} from '../../services/PushesService';
import {Typography} from "@material-ui/core";

type PushesHistoryListProps = {};

const NotificationsHistoryList = ({}: PushesHistoryListProps) => {
    const styles = {
        root: {
            flex: 1,
        },
        noContentLabel: {
            marginTop: "calc(100vh * 0.3)",
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
        }
    };

    const [pushes, setPushes] = useState<Array<HistoricPush>>();

    useEffect(() => {
        // Get all scheduled push notifications
        PushesService.GetHistoricPushes().then(pushes => {
            setPushes(pushes);
        })
    }, [])

    if (!pushes) {
        return (
            <div style={styles.root}>
                <CircularProgress color="inherit"/>
            </div>
        )
    }

    if (!pushes.length) {
        return (<div style={styles.root}>
            <div style={styles.noContentLabel}>
                <Typography variant="h6">
                    💬 Aktuell sind keine Benachrichtungen vorhanden
                </Typography>
            </div>
        </div>)
    }

    return (
        <div style={styles.root}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Kennung</TableCell>
                        <TableCell align="right">Titel</TableCell>
                        <TableCell align="right">Inhalt</TableCell>
                        <TableCell align="right">Sender</TableCell>
                        <TableCell align="right">Versandzeit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pushes.map((row) => (
                        <TableRow key={row.pushNotificationId}>
                            <TableCell align="right">{row.pushNotificationId}</TableCell>
                            <TableCell align="right">{row.pushNotificationTitle}</TableCell>
                            <TableCell align="right">{row.pushNotificationBody}</TableCell>
                            <TableCell align="right">{row.createdBy}</TableCell>
                            <TableCell align="right">{moment(row.sendTime).format('LLL')} Uhr</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default NotificationsHistoryList;
