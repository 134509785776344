import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { AdminsPage } from "../models/admin/AdminsPage";
import { AdminUserResponseDO } from "../models/admin/AdminUserResponseDO";
import { AdminUserRequestDO } from "../models/admin/AdminUserRequestDO";

const getAdmins = async (
  adminPage: AdminsPage
): Promise<AdminUserResponseDO[]> => {
  const { data } = await axios.get(`/backoffice/admins`, {
    params: adminPage,
  });
  return data;
};

const getAdminById = async (adminId: number): Promise<AdminUserResponseDO> => {
  const { data } = await axios.get(`/backoffice/admins/${adminId}`);
  return data;
};

const createAdmin = async (
  admin: AdminUserRequestDO
): Promise<AdminUserResponseDO> => {
  const { data } = await axios.post(`/backoffice/admins`, admin);
  return data;
};

const updateAdmin = async (
  admin: AdminUserRequestDO
): Promise<AdminUserResponseDO> => {
  const { data } = await axios.put(
    `/backoffice/admins/${admin.adminId}`,
    admin
  );
  return data;
};

const deleteAdminById = async (adminId: number): Promise<void> => {
  const { data } = await axios.delete(`/backoffice/admins/${adminId}`);
  return data;
};

// @ts-ignore
const defaultAdminPage: AdminsPage = {};

export const useGetAdmins = (adminPage: AdminsPage = defaultAdminPage) => {
  return useQuery<AdminUserResponseDO[], Error>(
    ["useGetAdmins"],
    () => getAdmins(adminPage),
    {}
  );
};

export const useCreateAdmin = () => {
  return useMutation(async (admin: AdminUserRequestDO) => {
    return await createAdmin(admin);
  }, {});
};

export const useUpdateAdmin = () => {
  return useMutation(async (admin: AdminUserRequestDO) => {
    return await updateAdmin(admin);
  }, {});
};

export const useGetAdminById = (adminId: number) => {
  return useQuery<AdminUserResponseDO, Error>(
    ["useGetAdminById", adminId],
    () => getAdminById(adminId),
    {}
  );
};

export const useDeleteAdminById = () => {
  return useMutation(async (adminId: number) => {
    return await deleteAdminById(adminId);
  }, {});
};
