import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

type ConfirmDialogType = {
  isOpen: boolean;
  title: string;
  content: string;
  onOk: () => void;
  onCancel: () => void;
};

export default function ConfirmDialog({
  isOpen,
  title,
  content,
  onOk,
  onCancel,
}: ConfirmDialogType) {
  const handleClose = () => {
    onCancel();
  };

  const handleOk = () => {
    handleClose();
    onOk();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
