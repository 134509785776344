import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { PushNotificationCategory } from "../models/category/PushNotificationCategory";
import { PushNotificationCategoryRequestDO } from "../models/category/PushNotificationCategoryRequestDO";

const getCategories = async (): Promise<PushNotificationCategory[]> => {
  const { data } = await axios.get(`/backoffice/push-categories`);
  return data;
};

const getCategoryById = async (
  categoryId: number
): Promise<PushNotificationCategory> => {
  const { data } = await axios.get(`/backoffice/push-categories/${categoryId}`);
  return data;
};

const createCategory = async (
  category: PushNotificationCategoryRequestDO
): Promise<PushNotificationCategory> => {
  const { data } = await axios.post(`/backoffice/push-categories`, category);
  return data;
};

const updateCategory = async (
  category: PushNotificationCategoryRequestDO
): Promise<PushNotificationCategory> => {
  const { data } = await axios.put(
    `/backoffice/push-categories/${category.pushNotificationCategoryId}`,
    category
  );
  return data;
};

const deleteCategoryById = async (
  categoryId: number
): Promise<PushNotificationCategory> => {
  const { data } = await axios.delete(
    `/backoffice/push-categories/${categoryId}`
  );
  return data;
};

export const useGetCategories = () => {
  return useQuery<PushNotificationCategory[], Error>(
    ["useGetCategories"],
    getCategories,
    {}
  );
};

export const useCreateCategory = () => {
  return useMutation(async (category: PushNotificationCategoryRequestDO) => {
    return await createCategory(category);
  }, {});
};

export const useUpdateCategory = () => {
  return useMutation(async (category: PushNotificationCategoryRequestDO) => {
    return await updateCategory(category);
  }, {});
};

export const useGetCategoryById = (categoryId: number) => {
  return useQuery<PushNotificationCategory, Error>(
    ["useGetCategoryById", categoryId],
    () => getCategoryById(categoryId),
    {}
  );
};

export const useDeleteCategoryById = () => {
  return useMutation(async (categoryId: number) => {
    return await deleteCategoryById(categoryId);
  }, {});
};
