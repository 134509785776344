import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { CouponVO } from "../models/coupon/CouponVO";
import { CouponRequestDO } from "../models/coupon/CouponRequestDO";

const getCoupons = async (): Promise<CouponVO[]> => {
  const { data } = await axios.get(`/backoffice/coupons`);
  return data;
};

const getCouponById = async (couponId: number): Promise<CouponVO> => {
  const { data } = await axios.get(`/backoffice/coupons/${couponId}`);
  return data;
};

const createCoupon = async (coupon: CouponRequestDO): Promise<CouponVO> => {
  const { data } = await axios.post(`/backoffice/coupons`, coupon);
  return data;
};

const updateCoupon = async (coupon: CouponRequestDO): Promise<CouponVO> => {
  const { data } = await axios.put(
    `/backoffice/coupons/${coupon?.couponId}`,
    coupon
  );
  return data;
};

const deleteCouponById = async (couponId: number): Promise<void> => {
  const { data } = await axios.delete(`/backoffice/coupons/${couponId}`);
  return data;
};

export const useGetCoupons = () => {
  return useQuery<CouponVO[], Error>(["useGetCoupon"], getCoupons, {});
};

export const useCreateCoupon = () => {
  return useMutation(async (coupon: CouponRequestDO) => {
    return await createCoupon(coupon);
  }, {});
};

export const useUpdateCoupon = () => {
  return useMutation(async (coupon: CouponRequestDO) => {
    return await updateCoupon(coupon);
  }, {});
};

export const useGetCouponById = (couponId: number) => {
  return useQuery<CouponVO, Error>(
    ["useGetCouponById", couponId],
    () => getCouponById(couponId),
    {}
  );
};

export const useDeleteCouponById = () => {
  return useMutation(async (couponId: number) => {
    return await deleteCouponById(couponId);
  }, {});
};
