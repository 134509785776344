import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

type WYSIWYGEditorProps = {
  defaultValue?: string;
  onChange?: (value: string) => void;
};
export const WYSIWYGEditor = ({
  defaultValue = "",
  onChange,
}: WYSIWYGEditorProps) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(stateFromHTML(defaultValue))
  );

  useEffect(() => {
    // If given defaultValue has at least some content
    if (defaultValue?.length > 11) {
      setEditorState(
        EditorState.createWithContent(stateFromHTML(defaultValue))
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange && onChange(stateToHTML(editorState.getCurrentContent()));
  }, [editorState, onChange]);

  return (
    <div className={"w-auto bg-white"}>
      <Editor
        editorState={editorState}
        wrapperStyle={{ minHeight: 450 }}
        editorStyle={{ paddingLeft: 16, paddingRight: 16 }}
        onEditorStateChange={setEditorState}
      />
    </div>
  );
};
