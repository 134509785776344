import React from "react";
import { useParams } from "react-router-dom";
import EventForm from "./EventForm";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useGetEventById, useGetEventProposals } from "../../queries/useEvent";

function EventEditPage() {
  const { eventId } = useParams<{ eventId: string }>();
  let { data: event, isLoading } = useGetEventById(parseInt(eventId));
  const { data: eventProposals } = useGetEventProposals();
  let eventProposal = event;

  if (!event?.isPublished) {
    eventProposal = eventProposals?.filter(
      (eventProposal) => eventProposal.id === event?.id
    )[0];
  }

  return (
    <div>
      <EventForm action={"EDIT"} event={eventProposal} />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default EventEditPage;
