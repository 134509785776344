import React from "react";
import { useParams } from "react-router-dom";
import { useGetAdminById } from "../../queries/useAdmin";
import AdminForm from "./AdminForm";
import BackDropFullScreen from "../../components/BackDropFullScreen";

function AdminEditPage() {
  const { adminId } = useParams<{ adminId: string }>();
  const { data: admin, isLoading } = useGetAdminById(parseInt(adminId));

  return (
    <div>
      <AdminForm action={"EDIT"} admin={admin} />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default AdminEditPage;
