import React, {useEffect, useState} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import {PushesService, ScheduledPush} from '../../services/PushesService';
import CircularProgress from '@material-ui/core/CircularProgress';
import {IconButton, Typography} from '@material-ui/core';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import {useHistory} from 'react-router-dom';

type PushesScheduledListProps = {};

const NotificationsScheduledList = ({}: PushesScheduledListProps) => {
  const styles = {
    root: {
      flex: 1,
    }, noContentLabel: {
      marginTop: "calc(100vh * 0.3)",
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
    }
  
  };
  
  const [pushes, setPushes] = useState<Array<ScheduledPush>>();
  
  const history = useHistory();
  
  useEffect(() => {
    // Get all scheduled push notifications
    PushesService.GetSchuledPushes().then(pushes => {
      console.log("Got schedules pushes => ", pushes)
      setPushes(pushes);
    })
  }, [])
  
  const onDelete = (push: ScheduledPush) => {
    PushesService.Delete(push.scheduledPushId).then(() => {
      PushesService.GetSchuledPushes().then(pushes => {
        setPushes(pushes);
      })
    }).catch(() => {
      alert("Ein unerwarteter Serverfehler ist aufgetreten.")
    })
  }
  
  if(!pushes) {
    return (
      <div style={styles.root}>
       <CircularProgress color="inherit"/>
      </div>
    )
  }
  
  /**
   * Keine Inhalte verfügbar
   */
  if(!pushes.length) {
    return (
      <div style={styles.root}>
        <div style={styles.noContentLabel}>
          <Typography variant="h6">
            💬 Aktuell sind keine Benachrichtungen in Planung
          </Typography>
        </div>
      </div>
    )
  }
  
  return (
    <div style={styles.root}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Kennung</TableCell>
            <TableCell align="right">Titel</TableCell>
            <TableCell align="right">Inhalt</TableCell>
            <TableCell align="right">Sender</TableCell>
            <TableCell align="right">Versandzeit</TableCell>
            <TableCell align="right">Versendet</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {pushes.map((row) => (
            <TableRow key={row.scheduledPushId}>
              <TableCell align="right">{row.scheduledPushId}</TableCell>
              <TableCell align="right">{row.scheduledPushTitle}</TableCell>
              <TableCell align="right">{row.scheduledPushBody}</TableCell>
              <TableCell align="right">{row.createdBy}</TableCell>
              <TableCell align="right">{moment(row.scheduledTime).format('LLL')} Uhr</TableCell>
              <TableCell align="right">{row.isSend ? <DoneAllIcon style={{color:'#8AC054'}} /> : <CloseIcon style={{color:'#d94452'}} />}</TableCell>
              <TableCell align="right">
                <IconButton size="small" style={{marginRight: 8}}>
                  <PageviewOutlinedIcon style={{color: '#0075AD'}} fontSize="inherit" onClick={() => {
                    history.push(`/push-notifications/${row.scheduledPushId}/edit`)
                  }} />
                </IconButton>
                <IconButton size="small">
                  <DeleteIcon style={{color: '#C3332C'}} fontSize="inherit" onClick={() => onDelete(row)} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default NotificationsScheduledList;
