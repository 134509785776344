import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { EventPrivateDO } from "../models/event/EventPrivateDO";
import { EventAdminRequestDO } from "../models/event/EventAdminRequestDO";

const getEvents = async (): Promise<EventPrivateDO[]> => {
  const { data } = await axios.get(`/backoffice/events`);
  return data;
};

const getEventProposals = async (): Promise<EventPrivateDO[]> => {
  const { data } = await axios.get(`/backoffice/events/proposals`);
  return data;
};

const getEventById = async (eventId: number): Promise<EventPrivateDO> => {
  const { data } = await axios.get(`/backoffice/events/${eventId}`);
  return data;
};

const createEvent = async (
  event: EventAdminRequestDO
): Promise<EventPrivateDO> => {
  const { data } = await axios.post(`/backoffice/events`, event);
  return data;
};

const updateEvent = async (
  event: EventAdminRequestDO
): Promise<EventPrivateDO> => {
  const { data } = await axios.put(`/backoffice/events/${event.id}`, event);
  return data;
};

const deleteEventById = async (eventId: number): Promise<void> => {
  const { data } = await axios.delete(`/backoffice/events/${eventId}`);
  return data;
};

export const useGetEvents = () => {
  return useQuery<EventPrivateDO[], Error>(
    ["useGetEvents"],
    () => getEvents(),
    {}
  );
};

export const useGetEventProposals = () => {
  return useQuery<EventPrivateDO[], Error>(
    ["useGetEventProposals"],
    () => getEventProposals(),
    {}
  );
};

export const useCreateEvent = () => {
  return useMutation(async (event: EventAdminRequestDO) => {
    return await createEvent(event);
  }, {});
};

export const useUpdateEvent = () => {
  return useMutation(async (event: EventAdminRequestDO) => {
    return await updateEvent(event);
  }, {});
};

export const useGetEventById = (eventId: number) => {
  return useQuery<EventPrivateDO, Error>(
    ["useGetEventById", eventId],
    () => getEventById(eventId),
    {}
  );
};

export const useDeleteEventById = () => {
  return useMutation(async (eventId: number) => {
    return await deleteEventById(eventId);
  }, {});
};
