import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Container,
  FormHelperText,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { CouponVO } from "../../models/coupon/CouponVO";
import { CouponRequestDO } from "../../models/coupon/CouponRequestDO";
import { useCreateCoupon, useUpdateCoupon } from "../../queries/useCoupon";
import { DateTimePicker } from "@material-ui/pickers";

type CouponFormType = {
  action: "DETAIL" | "EDIT" | "CREATE";
  coupon?: CouponVO;
};

const formDataInit: CouponRequestDO = {
  name: "",
  value: "",
  subline: "",
  description: "",
  validAt: new Date(),
  expiredAt: new Date(),
  image: "",
};

function CouponForm({ action, coupon }: CouponFormType) {
  const history = useHistory();
  const { couponId } = useParams<{ couponId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const createMutationResult = useCreateCoupon();
  const updateMutationResult = useUpdateCoupon();
  const queryClient = useQueryClient();
  const [image, setImage] = useState("");

  const requiredError = "Please fill out the field";
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CouponRequestDO>();

  useEffect(() => {
    reset({
      name: "",
      value: "",
      subline: "",
      description: "",
      validAt: new Date(),
      expiredAt: new Date(),
      image: "",
    });
    if (coupon !== undefined && action !== "CREATE") {
      formDataInit.name = coupon.name;
      formDataInit.value = coupon.value;
      formDataInit.subline = coupon.subline;
      formDataInit.description = coupon.description;
      formDataInit.validAt = coupon.validAt;
      formDataInit.expiredAt = coupon.expiredAt;
      formDataInit.image = coupon.image;
      setImage(coupon.image);
      reset(formDataInit);
    }
  }, [coupon, reset, action]);

  const onSubmit = async (data: CouponRequestDO) => {
    if (couponId) {
      data.couponId = parseInt(couponId);
    }
    try {
      if (image && image !== data.image) {
        data.image = image;
      }

      switch (action) {
        case "CREATE":
          await onCreate(data);
          break;
        case "EDIT":
          await onEdit(data);
          break;
      }
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(`Error: ${e.message}`, {
        variant: "error",
      });
    }
  };

  async function onEdit(data: CouponRequestDO) {
    await updateMutationResult.mutateAsync(data);
    await queryClient.invalidateQueries("useGetCoupons");
    await queryClient.invalidateQueries(["useGetCouponById", data.couponId]);
    enqueueSnackbar(`Coupon ${data.name} updated successfully!`, {
      variant: "success",
    });
    history.push("/coupons");
  }

  async function onCreate(data: CouponRequestDO) {
    await createMutationResult.mutate(data);
    enqueueSnackbar(`Coupon ${data.name} created successfully!`, {
      variant: "success",
    });
    history.push("/coupons");
    setTimeout(async () => {
      await queryClient.invalidateQueries("useGetCoupons");
      await queryClient.invalidateQueries([
        "useGetCouponById",
        createMutationResult.data?.couponId || "",
      ]);
    }, 1000);
  }

  const showHeading = () => {
    switch (action) {
      case "CREATE":
        return (
          <Typography component="h1" variant="h5">
            Coupon erstellen
          </Typography>
        );
      case "DETAIL":
        return (
          <Typography component="h1" variant="h5">
            Coupon: Detail
          </Typography>
        );
      case "EDIT":
        return (
          <Typography component="h1" variant="h5">
            Coupon bearbeiten
          </Typography>
        );
    }
  };

  const onChooseImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    const files = event.target.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
    onChange(event);
  };

  return (
    <Container className={""} component="main" maxWidth="xs">
      <div>
        {showHeading()}

        <form className={"w-full"}>
          <Controller
            key={"name"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Name"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.name?.message}
                />
                {errors?.name?.message && (
                  <FormHelperText error>{errors?.name?.message}</FormHelperText>
                )}
              </>
            )}
            name={"name"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.name}
          />

          <Controller
            key={"value"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Wert"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.value?.message}
                />
                {errors?.value?.message && (
                  <FormHelperText error>
                    {errors?.value?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"value"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.value}
          />

          <Controller
            key={"subline"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Fußzeile"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.subline?.message}
                />
                {errors?.subline?.message && (
                  <FormHelperText error>
                    {errors?.subline?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"subline"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.subline}
          />

          <Controller
            key={"description"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Beschreibung"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.description?.message}
                />
                {errors?.description?.message && (
                  <FormHelperText error>
                    {errors?.description?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"description"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.description}
          />

          <Controller
            key={"validAt"}
            control={control}
            render={({ field }) => (
              <>
                <DateTimePicker
                  {...field}
                  clearable
                  fullWidth
                  format="dd.MM.yyyy hh:mm' Uhr'"
                  margin="normal"
                  id="validAt"
                  label="Gültig von"
                  readOnly={action === "DETAIL"}
                  error={!!errors?.validAt?.message}
                />
                {errors?.validAt?.message && (
                  <FormHelperText error>
                    {errors?.validAt?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"validAt"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.validAt}
          />

          <Controller
            key={"expiredAt"}
            control={control}
            render={({ field }) => (
              <>
                <DateTimePicker
                  {...field}
                  clearable
                  fullWidth
                  format="dd.MM.yyyy hh:mm' Uhr'"
                  margin="normal"
                  id="expiredAt"
                  label="Gültig bis"
                  readOnly={action === "DETAIL"}
                  error={!!errors?.expiredAt?.message}
                />
                {errors?.expiredAt?.message && (
                  <FormHelperText error>
                    {errors?.expiredAt?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"expiredAt"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.expiredAt}
          />

          <Box my={2}>
            <Controller
              key={"image"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <Typography component="h5" variant="overline">
                    Optionale Grafik
                  </Typography>
                  {action !== "DETAIL" && (
                    <Box mb={2}>
                      <Button variant="contained" component="label">
                        Foto hochladen
                        <input
                          accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => onChooseImage(event, onChange)}
                        />
                      </Button>
                      {errors?.image?.message && (
                        <FormHelperText error>
                          {errors?.image?.message}
                        </FormHelperText>
                      )}
                    </Box>
                  )}

                  <Box mt={1}>
                    {image && (
                      <Card>
                        <CardActionArea>
                          <img src={image} />
                        </CardActionArea>
                      </Card>
                    )}
                  </Box>
                </>
              )}
              name={"image"}
              defaultValue={formDataInit.image}
            />
          </Box>

          <Box my={2}>
            {action !== "DETAIL" && (
              <Button
                disabled={
                  createMutationResult.isLoading ||
                  updateMutationResult.isLoading
                }
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {action === "EDIT" ? "Speichern" : "Erstellen"}
              </Button>
            )}
          </Box>

          <BackDropFullScreen
            isOpen={
              createMutationResult.isLoading || updateMutationResult.isLoading
            }
          />
        </form>
      </div>
    </Container>
  );
}

export default CouponForm;
