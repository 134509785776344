import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { calculateDuration } from "./TokenCheckHelper";
import { useAuth } from "../stores/useAuth";

export const SetupAxios = () => {
  /**
   * SETTING UP AXIOS
   */
  axios.defaults.baseURL = "https://gladbeck.app-pilots.de/api";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.put["Content-Type"] = "application/json";
  // Disable status code check to get then() called on every status
  // In that case we can handle custom exceptions and still get access
  // to the servers response instead of only an generic exception
  axios.defaults.validateStatus = (status) => status < 500;
  // Defining global exception handler. If status code is in error range
  // just create an exception but bundle response and status code
  // so the caller can managed different error messages based on this data
  axios.interceptors.request.use((axiosRequestConfig: AxiosRequestConfig) => {
    const { token, signOut } = useAuth.getState();

    if (token) {
      let expiredTime = moment.duration(calculateDuration(token)).asMinutes();
      if (expiredTime < 1) {
        signOut();
      }
      axiosRequestConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosRequestConfig;
  });

  axios.interceptors.response.use((response: AxiosResponse) => {
    const { signOut } = useAuth.getState();

    if (response.status < 300) {
      return response;
    }

    // Unauthenticated access
    if (response.status === 401) {
      signOut();
    }

    return Promise.reject(
      new NetworkError(
        response,
        response.status,
        `Request (${response.config.url}) failed with status code: ${
          response.status
        } \nBecause ${JSON.stringify(response.data.errors)}`
      )
    );
  });
};

export class NetworkError extends Error {
  constructor(
    public res: AxiosResponse,
    public statusCode: number,
    message?: string
  ) {
    super(message);
  }
}
