import React from "react";
import CouponForm from "./CouponForm";
import { useParams } from "react-router-dom";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useGetCouponById } from "../../queries/useCoupon";

function CouponDetailPage() {
  const { couponId } = useParams<{ couponId: string }>();
  const { data: coupon, isLoading } = useGetCouponById(parseInt(couponId));

  return (
    <div>
      <CouponForm action={"DETAIL"} coupon={coupon} />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default CouponDetailPage;
