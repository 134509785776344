import React from "react";
import CategoryForm from "./CategoryForm";

function CategoryCreatePage() {
  return (
    <div>
      <CategoryForm action={"CREATE"} />
    </div>
  );
}

export default CategoryCreatePage;
