import React, { useEffect } from "react";
import { Box, Container, FormHelperText } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { AdminUserResponseDO } from "../../models/admin/AdminUserResponseDO";
import { useCreateAdmin, useUpdateAdmin } from "../../queries/useAdmin";
import { AdminUserRequestDO } from "../../models/admin/AdminUserRequestDO";
import { useQueryClient } from "react-query";

type AdminFormType = {
  action: "DETAIL" | "EDIT" | "CREATE";
  admin?: AdminUserResponseDO;
};

const adminFormDataInit: AdminUserRequestDO = {
  email: "",
  password: "",
  firstname: "",
  lastname: "",
};

function AdminForm({ action, admin }: AdminFormType) {
  const history = useHistory();
  const { adminId } = useParams<{ adminId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const createMutationResult = useCreateAdmin();
  const updateMutationResult = useUpdateAdmin();
  const queryClient = useQueryClient();

  const requiredError = "Please fill out the field";
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AdminUserRequestDO>();

  useEffect(() => {
    reset({ lastname: "", firstname: "", email: "" });
    if (admin !== undefined && action !== "CREATE") {
      adminFormDataInit.email = admin.email;
      adminFormDataInit.firstname = admin.firstname;
      adminFormDataInit.lastname = admin.lastname;
      reset(adminFormDataInit);
    }
  }, [admin, reset, action]);

  const onSubmit = async (data: AdminUserRequestDO) => {
    if (adminId) {
      data.adminId = parseInt(adminId);
    }
    try {
      switch (action) {
        case "CREATE":
          await onCreate(data);
          break;
        case "EDIT":
          await onEdit(data);
          break;
      }
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(`Error: ${e.message}`, {
        variant: "error",
      });
    }
  };

  async function onCreate(data: AdminUserRequestDO) {
    await createMutationResult.mutate(data);
    enqueueSnackbar(`Admin ${data.firstname} created successfully!`, {
      variant: "success",
    });
    history.push("/admins");
    setTimeout(async () => {
      await queryClient.invalidateQueries("useGetAdmins");
      await queryClient.invalidateQueries([
        "useGetAdminById",
        createMutationResult.data?.adminId || "",
      ]);
    }, 1000);
  }

  async function onEdit(data: AdminUserRequestDO) {
    await updateMutationResult.mutateAsync(data);
    await queryClient.invalidateQueries("useGetAdmins");
    await queryClient.invalidateQueries(["useGetAdminById", data.adminId]);
    enqueueSnackbar(`Admin ${data.firstname} updated successfully!`, {
      variant: "success",
    });
    history.push("/admins");
  }

  const showHeading = () => {
    switch (action) {
      case "CREATE":
        return (
          <Typography component="h1" variant="h5">
            Administrator erstellen
          </Typography>
        );
      case "DETAIL":
        return (
          <Typography component="h1" variant="h5">
            Administrator: Detail
          </Typography>
        );
      case "EDIT":
        return (
          <Typography component="h1" variant="h5">
            Administrator bearbeiten
          </Typography>
        );
    }
  };

  return (
    <Container className={"mt-8"} component="main" maxWidth="xs">
      <div>
        {showHeading()}

        <form className={"w-full"}>
          <Controller
            key={"firstName"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Vorname"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.firstname?.message}
                />
                {errors?.firstname?.message && (
                  <FormHelperText error>
                    {errors?.firstname?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"firstname"}
            rules={{
              required: requiredError,
            }}
            defaultValue={adminFormDataInit.firstname}
          />

          <Controller
            key={"lastName"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{ readOnly: action === "DETAIL" }}
                  label="Nachname"
                  autoCapitalize={"none"}
                  error={!!errors?.lastname?.message}
                />
                {errors?.lastname?.message && (
                  <FormHelperText error>
                    {errors?.lastname?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"lastname"}
            rules={{
              required: requiredError,
            }}
            defaultValue={adminFormDataInit.lastname}
          />

          <Controller
            key={"email"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{ readOnly: action === "DETAIL" }}
                  label="Email"
                  autoCapitalize={"none"}
                  autoComplete={"email"}
                  error={!!errors?.email?.message}
                />
                {errors?.email?.message && (
                  <FormHelperText error>
                    {errors?.email?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"email"}
            rules={{
              required: requiredError,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email",
              },
            }}
            defaultValue={adminFormDataInit.email}
          />

          <Controller
            key={"password"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  type="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled={action === "DETAIL"}
                  inputProps={{ readOnly: action === "DETAIL" }}
                  label="Passwort"
                  autoCapitalize={"none"}
                  error={!!errors?.password?.message}
                />
                {errors?.password?.message && (
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"password"}
            rules={{
              required: requiredError,
            }}
            defaultValue={adminFormDataInit.password}
          />

          <Box my={2}>
            {action !== "DETAIL" && (
              <Button
                disabled={
                  createMutationResult.isLoading ||
                  updateMutationResult.isLoading
                }
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {action === "EDIT" ? "Speichern" : "Erstellen"}
              </Button>
            )}
          </Box>

          <BackDropFullScreen
            isOpen={
              createMutationResult.isLoading || updateMutationResult.isLoading
            }
          />
        </form>
      </div>
    </Container>
  );
}

export default AdminForm;
