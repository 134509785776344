import React, {useEffect, useState} from 'react';
import {Button, Chip, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import {PushesService, PushRangeStats} from '../../services/PushesService';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

import {useHistory} from 'react-router-dom';
import NotificationsScheduledList from './NotificationsScheduledList';
import NotificationsHistoryList from './NotificationsHistoryList';

type NotificationListProps = {};

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'yellow'
    },
    header: {
        fontWeight: 600
    },
}));

const NotificationList = ({}: NotificationListProps) => {

    const [stats, setStats] = useState<PushRangeStats>();
    const [index, setIndex] = useState(0);

    const history = useHistory();

    useEffect(() => {
        // Get statistics over pushes
        PushesService.GetPushStatistics()
            .then(data => {
                setStats(data);
            })
    }, [])

    const onCreate = () => {
        history.push(`/push-notifications/create`);
    }

    return (

        <div className={"w-full flex justify-center"} >
            <div className={"w-full"}>
                <div className={"flex justify-between mb-4"}>
                    <Typography variant={"h5"}>Push-Notifications</Typography>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={onCreate}
                    >
                        Erstellen
                    </Button>
                </div>

                <Typography variant={"caption"}>Folgende Auflistung zeigt alle geplanten und bereits versendeten
                    Benachrichtungen.</Typography>
                <div>
                    <br/>
                    <Typography variant={"body2"}>Reichweite</Typography>
                    <div style={{marginTop: 4}}>
                        <Chip
                            style={{marginRight: 4}}
                            icon={<AppleIcon/>}
                            label={`iOS: ${(stats && stats.iosCount) || "-"}`}
                            clickable
                            color="primary"
                        />
                        <Chip
                            icon={<AndroidIcon/>}
                            label={`Android: ${(stats && stats.androidCount) || "-"}`}
                            clickable
                            color="primary"
                        />
                    </div>
                </div>

                <Tabs value={index} indicatorColor="primary" style={{marginTop: 16}} onChange={(event, value) => {
                    setIndex(value)
                }} variant="fullWidth">
                    <Tab value={0} label="Gesendete Benachrichtigungen"/>
                    <Tab value={1} label="Geplante Benachrichtigungen"/>
                </Tabs>

                {index === 0 && <NotificationsHistoryList/>}
                {index === 1 && <NotificationsScheduledList/>}
            </div>
        </div>
    )
};

export default NotificationList;
