import React, { useEffect } from "react";
import { Box, Container, FormHelperText } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { PushNotificationCategory } from "../../models/category/PushNotificationCategory";
import { PushNotificationCategoryRequestDO } from "../../models/category/PushNotificationCategoryRequestDO";
import {
  useCreateCategory,
  useUpdateCategory,
} from "../../queries/useCategory";

type CategoryFormType = {
  action: "DETAIL" | "EDIT" | "CREATE";
  category?: PushNotificationCategory;
};

const formDataInit: PushNotificationCategoryRequestDO = {
  categoryIdentifier: "",
  categoryShort: "",
  categoryDescription: "",
};

function CategoryForm({ action, category }: CategoryFormType) {
  const history = useHistory();
  const { categoryId } = useParams<{ categoryId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const createMutationResult = useCreateCategory();
  const updateMutationResult = useUpdateCategory();
  const queryClient = useQueryClient();

  const requiredError = "Please fill out the field";
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PushNotificationCategoryRequestDO>();

  useEffect(() => {
    reset({
      categoryIdentifier: "",
      categoryShort: "",
      categoryDescription: "",
    });
    if (category !== undefined && action !== "CREATE") {
      formDataInit.categoryIdentifier = category.categoryIdentifier;
      formDataInit.categoryShort = category.categoryShort;
      formDataInit.categoryDescription = category.categoryDescription;
      reset(formDataInit);
    }
  }, [category, reset, action]);

  const onSubmit = async (data: PushNotificationCategoryRequestDO) => {
    if (categoryId) {
      data.pushNotificationCategoryId = parseInt(categoryId);
    }
    try {
      switch (action) {
        case "CREATE":
          await onCreate(data);
          break;
        case "EDIT":
          await onEdit(data);
          break;
      }
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(`Error: ${e.message}`, {
        variant: "error",
      });
    }
  };

  async function onCreate(data: PushNotificationCategoryRequestDO) {
    await createMutationResult.mutate(data);
    enqueueSnackbar(`Category ${data.categoryShort} created successfully!`, {
      variant: "success",
    });
    history.push("/categories");
    setTimeout(async () => {
      await queryClient.invalidateQueries("useGetCategories");
      await queryClient.invalidateQueries([
        "useGetCategoryById",
        createMutationResult.data?.pushNotificationCategoryId || "",
      ]);
    }, 1000);
  }

  async function onEdit(data: PushNotificationCategoryRequestDO) {
    await updateMutationResult.mutateAsync(data);
    await queryClient.invalidateQueries("useGetCategories");
    await queryClient.invalidateQueries([
      "useGetCategoryById",
      data.pushNotificationCategoryId,
    ]);
    enqueueSnackbar(`Category ${data.categoryShort} updated successfully!`, {
      variant: "success",
    });
    history.push("/categories");
  }

  const showHeading = () => {
    switch (action) {
      case "CREATE":
        return (
          <Typography component="h1" variant="h5">
            Kategorie: Erstellen
          </Typography>
        );
      case "DETAIL":
        return (
          <Typography component="h1" variant="h5">
            Kategorie: Detail
          </Typography>
        );
      case "EDIT":
        return (
          <Typography component="h1" variant="h5">
            Kategorie: Bearbeiten
          </Typography>
        );
    }
  };

  return (
    <Container className={"mt-8"} component="main" maxWidth="xs">
      <div>
        {showHeading()}

        <form className={"w-full"}>
          <Controller
            key={"identifier"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Identifier"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.categoryIdentifier?.message}
                />
                {errors?.categoryIdentifier?.message && (
                  <FormHelperText error>
                    {errors?.categoryIdentifier?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"categoryIdentifier"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.categoryIdentifier}
          />

          <Controller
            key={"shortDescription"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Short description"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.categoryShort?.message}
                />
                {errors?.categoryShort?.message && (
                  <FormHelperText error>
                    {errors?.categoryShort?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"categoryShort"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.categoryShort}
          />

          <Controller
            key={"description"}
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Description"
                  inputProps={{ readOnly: action === "DETAIL" }}
                  autoCapitalize={"none"}
                  error={!!errors?.categoryDescription?.message}
                />
                {errors?.categoryDescription?.message && (
                  <FormHelperText error>
                    {errors?.categoryDescription?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"categoryDescription"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.categoryDescription}
          />

          <Box my={2}>
            {action !== "DETAIL" && (
              <Button
                disabled={
                  createMutationResult.isLoading ||
                  updateMutationResult.isLoading
                }
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {action === "EDIT" ? "Save" : "Create"}
              </Button>
            )}
          </Box>

          <BackDropFullScreen
            isOpen={
              createMutationResult.isLoading || updateMutationResult.isLoading
            }
          />
        </form>
      </div>
    </Container>
  );
}

export default CategoryForm;
