import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { routes, RouteType } from "./MainSwitch";
import MainAppBar from "./MainAppBar";
import { ListItemIcon } from "@material-ui/core";
import { useAuth } from "../../stores/useAuth";

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
    height: "100vh",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function MainLayout(props: any) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { token } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const showRoute = (route: RouteType) => {
    return (
      <ListItem
        button
        key={route.routePath}
        component={Link}
        to={route.routePath}
        onClick={() => onClickRoute(route)}
      >
        <ListItemIcon>{route.routeIcon}</ListItemIcon>
        <ListItemText primary={route.routeText} />
      </ListItem>
    );
  };

  const onClickRoute = (route: RouteType) => {
    if (route?.onClick) {
      route?.onClick();
    }
    handleDrawerToggle();
  };

  const showDrawer = (
    <div>
      <Hidden smDown>
        <div className={classes.toolbar} />
      </Hidden>

      {/* Protected Routes */}
      {token && (
        <>
          <Divider />
          <List>
            {routes
              .filter((route: RouteType) => !route.routePath.includes("auth"))
              .map(showRoute)}
          </List>
        </>
      )}

      {/* Auth Routes */}
      <Divider />
      <List>
        {!token &&
          routes
            .filter((route: RouteType) => route.routeText.includes("Anmelden"))
            .map(showRoute)}

        {token &&
          routes
            .filter((route: RouteType) => route.routeText.includes("Abmelden"))
            .map(showRoute)}
      </List>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <MainAppBar handleDrawerToggle={handleDrawerToggle} />

        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {showDrawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {showDrawer}
          </Drawer>
        </Hidden>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </div>
    </>
  );
}

export default MainLayout;
