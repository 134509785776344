import React, { useState } from "react";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useDeleteCouponById, useGetCoupons } from "../../queries/useCoupon";
import { CouponVO } from "../../models/coupon/CouponVO";
import moment from "moment";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function CouponsPage() {
  const { data: coupons, isLoading } = useGetCoupons();
  const delMutationResult = useDeleteCouponById();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [toDelete, setToDelete] = useState<CouponVO>();

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    coupon: CouponVO
  ) => {
    history.push(`/coupons/${coupon.couponId}`);
  };

  const onCreate = () => {
    history.push("/coupons/create");
  };

  const onEdit = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    coupon: CouponVO
  ) => {
    event.stopPropagation();
    history.push(`/coupons/${coupon.couponId}/edit`);
  };

  const onDelete = async () => {
    try {
      await delMutationResult.mutateAsync(toDelete?.couponId as number);
      await queryClient.invalidateQueries("useGetCoupons");
      enqueueSnackbar(`Delete coupon ${toDelete?.name} successfully!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(
        `Delete coupon ${toDelete?.name} with error: ${e.message}`,
        {
          variant: "error",
        }
      );
    }
  };

  const openDialog = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    coupon: CouponVO
  ) => {
    event.stopPropagation();
    setOpen(true);
    setToDelete(coupon);
  };

  return (
    <div className={"w-full flex justify-center"}>
      <div className={"max-w-4xl"}>
        <div className={"flex justify-between mb-4"}>
          <Typography variant={"h5"}>Coupons</Typography>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onCreate}
          >
            Erstellen
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Wert</TableCell>
                <TableCell align="right">Gültig von</TableCell>
                <TableCell align="right">Gültig bis</TableCell>
                <TableCell align="right">Einlösungen</TableCell>
                <TableCell align="right">Ist aktiv?</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons &&
                coupons.map((coupon: CouponVO) => (
                  <TableRow
                    className={"cursor-pointer"}
                    hover
                    key={coupon?.couponId}
                    onClick={(event) => onRowClick(event, coupon)}
                  >
                    <TableCell component="th" scope="row">
                      {coupon?.name}
                    </TableCell>
                    <TableCell align="right">{coupon?.value}</TableCell>
                    <TableCell align="right">
                      {moment(coupon?.validAt).format("DD.MM.YYYY - hh:mm")}
                    </TableCell>
                    <TableCell align="right">
                      {moment(coupon?.expiredAt).format("DD.MM.YYYY - hh:mm")}
                    </TableCell>
                    <TableCell align="right">{coupon?.redeemCount}</TableCell>
                    <TableCell align="right">
                      {coupon?.active ? <CheckIcon color={"primary"} /> : <ClearIcon color={"error"} />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(event) => onEdit(event, coupon)}>
                        <PageviewOutlinedIcon color={"primary"} />
                      </IconButton>

                      <IconButton
                        onClick={(event) => openDialog(event, coupon)}
                      >
                        <DeleteIcon color={"error"} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <ConfirmDialog
        isOpen={open}
        title={"Delete Coupon"}
        content={"Still wanna delete?"}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
      />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default CouponsPage;
