import jwt_decode from "jwt-decode";
import moment from "moment";

/**
 * Calculate new time until token expires
 * @param token
 */
export const calculateDuration = (token: string): number => {
  const decodedToken = jwt_decode(token) as any;
  return moment.unix(decodedToken.exp).diff(moment().toDate());
};
