import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

type MainAppBarType = {
  handleDrawerToggle: () => void;
};

function MainAppBar({ handleDrawerToggle }: MainAppBarType) {
  const classes = useStyles();

  return (
    <AppBar position="absolute" className={classes.appBar} color={"inherit"}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          CityAPP - Verwaltung
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default MainAppBar;
