export enum MenuItemNativeType {
  NEWS = "news",
  EVENTS = "events",
  COUPONS = "coupons",
  CITY_MAP = "cityMap",
  POIS = "pois",
  METER_READING = "meterReading",
  DEFECT_FORM = "defectForm",
  WASTE_CALENDER = "wasteCalender",
}
