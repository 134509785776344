import React from "react";
import { useParams } from "react-router-dom";
import CategoryForm from "./CategoryForm";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useGetCategoryById } from "../../queries/useCategory";

function CategoryEditPage() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { data: category, isLoading } = useGetCategoryById(
    parseInt(categoryId)
  );

  return (
    <div>
      <CategoryForm action={"EDIT"} category={category} />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default CategoryEditPage;
