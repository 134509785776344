import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDeleteMenu, useGetMenu } from "../../queries/useMenu";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuForm from "./MenuForm";
import { MenuItemDO } from "../../models/menu/MenuItemDO";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useQueryClient } from "react-query";

function MenuPage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { data: menus, isLoading } = useGetMenu();
  const [menuItem, setMenuItem] = useState<MenuItemDO | undefined>();
  const [resetForm, setResetForm] = useState(false);
  const [toDelete, setToDelete] = useState<MenuItemDO>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const delMutationResult = useDeleteMenu();
  const queryClient = useQueryClient();

  const onNewMenuItem = () => {
    setResetForm(!resetForm);
    setMenuItem(undefined);
  };

  const onChooseMenuItem = (menuItem: MenuItemDO) => {
    setMenuItem(menuItem);
  };

  const openDeleteDialog = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    menuItemDO: MenuItemDO
  ) => {
    event.stopPropagation();
    setDeleteOpen(true);
    setToDelete(menuItemDO);
  };

  const onDelete = async () => {
    try {
      await delMutationResult.mutateAsync(toDelete?.menuItemId as number);
      await queryClient.invalidateQueries("useGetMenu");
      enqueueSnackbar(`Delete menu ${toDelete?.title[0].value} successfully!`, {
        variant: "success",
      });
      setMenuItem(undefined);
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(
        `Delete menu ${toDelete?.title} with error: ${e.message}`,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Container className={""} component="main" maxWidth="lg">
      <div className={"md:flex"}>
        <div className={"md:w-1/3 pr-4"}>
          <Box my={2}>
            <Typography component="h5" variant="h5">
              Menu structure
            </Typography>
          </Box>

          <div>
            <List subheader={<ListSubheader>Root</ListSubheader>}>
              {isLoading && (
                <div className={"flex justify-center"}>
                  <CircularProgress color="secondary" />
                </div>
              )}

              <div className={"ml-5"}>
                {menus &&
                  menus.map((menuItem) => (
                    <div>
                      <MenuItem
                        key={menuItem.menuItemId}
                        value={menuItem.menuItemId}
                        onClick={() => onChooseMenuItem(menuItem)}
                      >
                        <div
                          className={"w-full flex justify-between items-center"}
                        >
                          <div>
                            {menuItem.title[0].value || menuItem.title[1].value}
                          </div>
                          <IconButton
                            onClick={(event) =>
                              openDeleteDialog(event, menuItem)
                            }
                          >
                            <DeleteIcon color={"error"} />
                          </IconButton>
                        </div>
                      </MenuItem>

                      <div className={"ml-3"}>
                        {menuItem.children.map((menuChild) => (
                          <MenuItem
                            key={menuChild.menuItemId}
                            value={menuChild.menuItemId}
                            onClick={() => onChooseMenuItem(menuChild)}
                          >
                            <div
                              className={
                                "w-full flex justify-between items-center"
                              }
                            >
                              <div>{menuChild.title[0].value}</div>
                              <IconButton
                                onClick={(event) =>
                                  openDeleteDialog(event, menuChild)
                                }
                              >
                                <DeleteIcon color={"error"} />
                              </IconButton>
                            </div>
                          </MenuItem>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </List>
          </div>

          <Box my={2}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={onNewMenuItem}
            >
              New menu item
            </Button>
          </Box>
        </div>

        <div className={"md:w-2/3"}>
          <MenuForm
            menuItem={menuItem}
            isFormReset={resetForm}
            onNewMenuItem={onNewMenuItem}
          />
        </div>

        <ConfirmDialog
          isOpen={deleteOpen}
          title={"Delete Event"}
          content={"Still wanna delete?"}
          onOk={onDelete}
          onCancel={() => setDeleteOpen(false)}
        />
      </div>
    </Container>
  );
}

export default MenuPage;
