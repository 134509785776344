import React, { useEffect } from "react";
import "./App.css";
import { CssBaseline } from "@material-ui/core";
import MainLayout from "./components/layout/MainLayout";
import { BrowserRouter as Router } from "react-router-dom";
import MainSwitch from "./components/layout/MainSwitch";
import { QueryClient, QueryClientProvider } from "react-query";
import { SetupAxios } from "./config/AxiosConfig";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Create a client
const queryClient = new QueryClient();

SetupAxios();

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <CssBaseline />
            <MainLayout>
              <MainSwitch />
            </MainLayout>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
}

export default App;
