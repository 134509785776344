import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { MenuItemDO } from "../models/menu/MenuItemDO";
import { MenuItemCreateRequestDO } from "../models/menu/MenuItemCreateRequestDO";
import { MenuItemUpdateRequestDO } from "../models/menu/MenuItemUpdateRequestDO";

const getMenu = async (): Promise<MenuItemDO[]> => {
  const { data } = await axios.get(`/backoffice/menu`);
  return data;
};

const createMenu = async (
  menuItemCreateRequestDO: MenuItemCreateRequestDO
): Promise<MenuItemDO> => {
  const { data } = await axios.post(
    `/backoffice/menu`,
    menuItemCreateRequestDO
  );
  return data;
};

const updateMenu = async (
  menuItemUpdateRequestDO: MenuItemUpdateRequestDO
): Promise<MenuItemDO> => {
  const { data } = await axios.put(`/backoffice/menu`, menuItemUpdateRequestDO);
  return data;
};

const deleteMenu = async (menuItemId: number): Promise<void> => {
  const { data } = await axios.delete(`/backoffice/menu/${menuItemId}`);
  return data;
};

export const useGetMenu = () => {
  return useQuery<MenuItemDO[], Error>(["useGetMenu"], () => getMenu(), {});
};

export const useCreateMenu = () => {
  return useMutation(
    async (menuItemCreateRequestDO: MenuItemCreateRequestDO) => {
      return await createMenu(menuItemCreateRequestDO);
    },
    {}
  );
};

export const useUpdateMenu = () => {
  return useMutation(
    async (menuItemUpdateRequestDO: MenuItemUpdateRequestDO) => {
      return await updateMenu(menuItemUpdateRequestDO);
    },
    {}
  );
};

export const useDeleteMenu = () => {
  return useMutation(async (menuItemId: number) => {
    return await deleteMenu(menuItemId);
  }, {});
};
